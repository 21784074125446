// @generated by protobuf-ts 2.9.1 with parameter long_type_string,generate_dependencies
// @generated from protobuf file "core/v1/core.proto" (package "core.v1", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Any } from "../../google/protobuf/any";
import { Struct } from "../../google/protobuf/struct";
/**
 * @generated from protobuf message core.v1.RelationTuple
 */
export interface RelationTuple {
    /**
     * * resource_and_relation is the resource for the tuple
     *
     * @generated from protobuf field: core.v1.ObjectAndRelation resource_and_relation = 1;
     */
    resourceAndRelation?: ObjectAndRelation;
    /**
     * * subject is the subject for the tuple
     *
     * @generated from protobuf field: core.v1.ObjectAndRelation subject = 2;
     */
    subject?: ObjectAndRelation;
    /**
     * * caveat is a reference to a the caveat that must be enforced over the tuple *
     *
     * @generated from protobuf field: core.v1.ContextualizedCaveat caveat = 3;
     */
    caveat?: ContextualizedCaveat;
}
/**
 * *
 * ContextualizedCaveat represents a reference to a caveat used to by caveated tuples.
 * The context are key-value pairs that will be injected at evaluation time.
 *
 * @generated from protobuf message core.v1.ContextualizedCaveat
 */
export interface ContextualizedCaveat {
    /**
     * * caveat_name is the name used in the schema for a stored caveat *
     *
     * @generated from protobuf field: string caveat_name = 1;
     */
    caveatName: string;
    /**
     * * context are arguments used as input during caveat evaluation with a predefined value *
     *
     * @generated from protobuf field: google.protobuf.Struct context = 2;
     */
    context?: Struct;
}
/**
 * @generated from protobuf message core.v1.CaveatDefinition
 */
export interface CaveatDefinition {
    /**
     * * name represents the globally-unique identifier of the caveat *
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * * serialized_expression is the byte representation of a caveat's logic
     *
     * @generated from protobuf field: bytes serialized_expression = 2;
     */
    serializedExpression: Uint8Array;
    /**
     * * parameters_and_types is a map from parameter name to its type
     *
     * @generated from protobuf field: map<string, core.v1.CaveatTypeReference> parameter_types = 3;
     */
    parameterTypes: {
        [key: string]: CaveatTypeReference;
    };
    /**
     * * metadata contains compiler metadata from schemas compiled into caveats
     *
     * @generated from protobuf field: core.v1.Metadata metadata = 4;
     */
    metadata?: Metadata;
    /**
     * * source_position contains the position of the caveat in the source schema, if any
     *
     * @generated from protobuf field: core.v1.SourcePosition source_position = 5;
     */
    sourcePosition?: SourcePosition;
}
/**
 * @generated from protobuf message core.v1.CaveatTypeReference
 */
export interface CaveatTypeReference {
    /**
     * @generated from protobuf field: string type_name = 1;
     */
    typeName: string;
    /**
     * @generated from protobuf field: repeated core.v1.CaveatTypeReference child_types = 2;
     */
    childTypes: CaveatTypeReference[];
}
/**
 * @generated from protobuf message core.v1.ObjectAndRelation
 */
export interface ObjectAndRelation {
    /**
     * * namespace is the full namespace path for the referenced object
     *
     * @generated from protobuf field: string namespace = 1;
     */
    namespace: string;
    /**
     * * object_id is the unique ID for the object within the namespace
     *
     * @generated from protobuf field: string object_id = 2;
     */
    objectId: string;
    /**
     * * relation is the name of the referenced relation or permission under the namespace
     *
     * @generated from protobuf field: string relation = 3;
     */
    relation: string;
}
/**
 * @generated from protobuf message core.v1.RelationReference
 */
export interface RelationReference {
    /**
     * * namespace is the full namespace path
     *
     * @generated from protobuf field: string namespace = 1;
     */
    namespace: string;
    /**
     * * relation is the name of the referenced relation or permission under the namespace
     *
     * @generated from protobuf field: string relation = 3;
     */
    relation: string;
}
/**
 * @generated from protobuf message core.v1.Zookie
 */
export interface Zookie {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * @generated from protobuf message core.v1.RelationTupleUpdate
 */
export interface RelationTupleUpdate {
    /**
     * @generated from protobuf field: core.v1.RelationTupleUpdate.Operation operation = 1;
     */
    operation: RelationTupleUpdate_Operation;
    /**
     * @generated from protobuf field: core.v1.RelationTuple tuple = 2;
     */
    tuple?: RelationTuple;
}
/**
 * @generated from protobuf enum core.v1.RelationTupleUpdate.Operation
 */
export enum RelationTupleUpdate_Operation {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: CREATE = 1;
     */
    CREATE = 1,
    /**
     * @generated from protobuf enum value: TOUCH = 2;
     */
    TOUCH = 2,
    /**
     * @generated from protobuf enum value: DELETE = 3;
     */
    DELETE = 3
}
/**
 * @generated from protobuf message core.v1.RelationTupleTreeNode
 */
export interface RelationTupleTreeNode {
    /**
     * @generated from protobuf oneof: node_type
     */
    nodeType: {
        oneofKind: "intermediateNode";
        /**
         * @generated from protobuf field: core.v1.SetOperationUserset intermediate_node = 1;
         */
        intermediateNode: SetOperationUserset;
    } | {
        oneofKind: "leafNode";
        /**
         * @generated from protobuf field: core.v1.DirectSubjects leaf_node = 2;
         */
        leafNode: DirectSubjects;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: core.v1.ObjectAndRelation expanded = 3;
     */
    expanded?: ObjectAndRelation;
    /**
     * @generated from protobuf field: core.v1.CaveatExpression caveat_expression = 4;
     */
    caveatExpression?: CaveatExpression;
}
/**
 * @generated from protobuf message core.v1.SetOperationUserset
 */
export interface SetOperationUserset {
    /**
     * @generated from protobuf field: core.v1.SetOperationUserset.Operation operation = 1;
     */
    operation: SetOperationUserset_Operation;
    /**
     * @generated from protobuf field: repeated core.v1.RelationTupleTreeNode child_nodes = 2;
     */
    childNodes: RelationTupleTreeNode[];
}
/**
 * @generated from protobuf enum core.v1.SetOperationUserset.Operation
 */
export enum SetOperationUserset_Operation {
    /**
     * @generated from protobuf enum value: INVALID = 0;
     */
    INVALID = 0,
    /**
     * @generated from protobuf enum value: UNION = 1;
     */
    UNION = 1,
    /**
     * @generated from protobuf enum value: INTERSECTION = 2;
     */
    INTERSECTION = 2,
    /**
     * @generated from protobuf enum value: EXCLUSION = 3;
     */
    EXCLUSION = 3
}
/**
 * @generated from protobuf message core.v1.DirectSubject
 */
export interface DirectSubject {
    /**
     * @generated from protobuf field: core.v1.ObjectAndRelation subject = 1;
     */
    subject?: ObjectAndRelation;
    /**
     * @generated from protobuf field: core.v1.CaveatExpression caveat_expression = 2;
     */
    caveatExpression?: CaveatExpression;
}
/**
 * @generated from protobuf message core.v1.DirectSubjects
 */
export interface DirectSubjects {
    /**
     * @generated from protobuf field: repeated core.v1.DirectSubject subjects = 1;
     */
    subjects: DirectSubject[];
}
/**
 * *
 * Metadata is compiler metadata added to namespace definitions, such as doc comments and
 * relation kinds.
 *
 * @generated from protobuf message core.v1.Metadata
 */
export interface Metadata {
    /**
     * @generated from protobuf field: repeated google.protobuf.Any metadata_message = 1;
     */
    metadataMessage: Any[];
}
/**
 * *
 * NamespaceDefinition represents a single definition of an object type
 *
 * @generated from protobuf message core.v1.NamespaceDefinition
 */
export interface NamespaceDefinition {
    /**
     * * name is the unique for the namespace, including prefixes (which are optional)
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * * relation contains the relations and permissions defined in the namespace
     *
     * @generated from protobuf field: repeated core.v1.Relation relation = 2;
     */
    relation: Relation[];
    /**
     * * metadata contains compiler metadata from schemas compiled into namespaces
     *
     * @generated from protobuf field: core.v1.Metadata metadata = 3;
     */
    metadata?: Metadata;
    /**
     * * source_position contains the position of the namespace in the source schema, if any
     *
     * @generated from protobuf field: core.v1.SourcePosition source_position = 4;
     */
    sourcePosition?: SourcePosition;
}
/**
 * *
 * Relation represents the definition of a relation or permission under a namespace.
 *
 * @generated from protobuf message core.v1.Relation
 */
export interface Relation {
    /**
     * * name is the full name for the relation or permission
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * * userset_rewrite, if specified, is the rewrite for computing the value of the permission.
     *
     * @generated from protobuf field: core.v1.UsersetRewrite userset_rewrite = 2;
     */
    usersetRewrite?: UsersetRewrite;
    /**
     * *
     * type_information, if specified, is the list of allowed object types that can appear in this
     * relation
     *
     * @generated from protobuf field: core.v1.TypeInformation type_information = 3;
     */
    typeInformation?: TypeInformation;
    /**
     * * metadata contains compiler metadata from schemas compiled into namespaces
     *
     * @generated from protobuf field: core.v1.Metadata metadata = 4;
     */
    metadata?: Metadata;
    /**
     * * source_position contains the position of the relation in the source schema, if any
     *
     * @generated from protobuf field: core.v1.SourcePosition source_position = 5;
     */
    sourcePosition?: SourcePosition;
    /**
     * @generated from protobuf field: string aliasing_relation = 6;
     */
    aliasingRelation: string;
    /**
     * @generated from protobuf field: string canonical_cache_key = 7;
     */
    canonicalCacheKey: string;
}
/**
 * *
 * ReachabilityGraph is a serialized form of a reachability graph, representing how a relation can
 * be reached from one or more subject types.
 *
 * It defines a "reverse" data flow graph, starting at a subject type, and providing all the
 * entrypoints where that subject type can be found leading to the decorated relation.
 *
 * For example, given the schema:
 * ```
 *   definition user {}
 *
 *   definition organization {
 *     relation admin: user
 *   }
 *
 *   definition resource {
 *     relation org: organization
 *     relation viewer: user
 *     relation owner: user
 *     permission view = viewer + owner + org->admin
 *   }
 * ```
 *
 * The reachability graph for `viewer` and the other relations will have entrypoints for each
 * subject type found for those relations.
 *
 * The full reachability graph for the `view` relation will have three entrypoints, representing:
 *   1) resource#viewer (computed_userset)
 *   2) resource#owner  (computed_userset)
 *   3) organization#admin (tupleset_to_userset)
 *
 * @generated from protobuf message core.v1.ReachabilityGraph
 */
export interface ReachabilityGraph {
    /**
     * *
     * entrypoints_by_subject_type provides all entrypoints by subject *type*, representing wildcards.
     * The keys of the map are the full path(s) for the namespace(s) referenced by reachable wildcards
     *
     * @generated from protobuf field: map<string, core.v1.ReachabilityEntrypoints> entrypoints_by_subject_type = 1;
     */
    entrypointsBySubjectType: {
        [key: string]: ReachabilityEntrypoints;
    };
    /**
     * *
     * entrypoints_by_subject_relation provides all entrypoints by subject type+relation.
     * The keys of the map are of the form `namespace_path#relation_name`
     *
     * @generated from protobuf field: map<string, core.v1.ReachabilityEntrypoints> entrypoints_by_subject_relation = 2;
     */
    entrypointsBySubjectRelation: {
        [key: string]: ReachabilityEntrypoints;
    };
}
/**
 * *
 * ReachabilityEntrypoints represents all the entrypoints for a specific subject type or subject
 * relation into the reachability graph for a particular target relation.
 *
 * @generated from protobuf message core.v1.ReachabilityEntrypoints
 */
export interface ReachabilityEntrypoints {
    /**
     * *
     * entrypoints are the entrypoints found.
     *
     * @generated from protobuf field: repeated core.v1.ReachabilityEntrypoint entrypoints = 1;
     */
    entrypoints: ReachabilityEntrypoint[];
    /**
     * *
     * subject_type, if specified, is the type of subjects to which the entrypoint(s) apply. A
     * subject type is only set for wildcards.
     *
     * @generated from protobuf field: string subject_type = 2;
     */
    subjectType: string;
    /**
     * *
     * subject_relation, if specified, is the type and relation of subjects to which the
     * entrypoint(s) apply.
     *
     * @generated from protobuf field: core.v1.RelationReference subject_relation = 3;
     */
    subjectRelation?: RelationReference;
}
/**
 * *
 * ReachabilityEntrypoint represents a single entrypoint for a specific subject type or subject
 * relation into the reachability graph for a particular target relation.
 *
 * @generated from protobuf message core.v1.ReachabilityEntrypoint
 */
export interface ReachabilityEntrypoint {
    /**
     * *
     * kind is the kind of the entrypoint.
     *
     * @generated from protobuf field: core.v1.ReachabilityEntrypoint.ReachabilityEntrypointKind kind = 1;
     */
    kind: ReachabilityEntrypoint_ReachabilityEntrypointKind;
    /**
     * *
     * target_relation is the relation on which the entrypoint exists.
     *
     * @generated from protobuf field: core.v1.RelationReference target_relation = 2;
     */
    targetRelation?: RelationReference;
    /**
     * *
     * result_status contains the status of objects found for this entrypoint as direct results for
     * the parent relation/permission.
     *
     * @generated from protobuf field: core.v1.ReachabilityEntrypoint.EntrypointResultStatus result_status = 4;
     */
    resultStatus: ReachabilityEntrypoint_EntrypointResultStatus;
    /**
     * *
     * tupleset_relation is the name of the tupleset relation on the TupleToUserset this entrypoint
     * represents, if applicable.
     *
     * @generated from protobuf field: string tupleset_relation = 5;
     */
    tuplesetRelation: string;
    /**
     * *
     * computed_userset_relation is the name of the computed userset relation on the ComputedUserset
     * this entrypoint represents, if applicable.
     *
     * @generated from protobuf field: string computed_userset_relation = 6;
     */
    computedUsersetRelation: string;
}
/**
 * @generated from protobuf enum core.v1.ReachabilityEntrypoint.ReachabilityEntrypointKind
 */
export enum ReachabilityEntrypoint_ReachabilityEntrypointKind {
    /**
     * *
     * RELATION_ENTRYPOINT indicates an entrypoint where the subject object can be directly
     * found for a relationship.
     *
     * @generated from protobuf enum value: RELATION_ENTRYPOINT = 0;
     */
    RELATION_ENTRYPOINT = 0,
    /**
     * *
     * COMPUTED_USERSET_ENTRYPOINT indicates an entrypoint where the subject's relation is
     * "rewritten" via a `computed_userset` to the target permission's operation node.
     *
     * @generated from protobuf enum value: COMPUTED_USERSET_ENTRYPOINT = 1;
     */
    COMPUTED_USERSET_ENTRYPOINT = 1,
    /**
     * *
     * TUPLESET_TO_USERSET_ENTRYPOINT indicates an entrypoint where the subject's relation is
     * walked via a `tupleset_to_userset` in the target permission's operation node.
     *
     * @generated from protobuf enum value: TUPLESET_TO_USERSET_ENTRYPOINT = 2;
     */
    TUPLESET_TO_USERSET_ENTRYPOINT = 2
}
/**
 * @generated from protobuf enum core.v1.ReachabilityEntrypoint.EntrypointResultStatus
 */
export enum ReachabilityEntrypoint_EntrypointResultStatus {
    /**
     * *
     * REACHABLE_CONDITIONAL_RESULT indicates that the entrypoint is under one or more intersections
     * or exclusion operations, indicating that any reachable object *may* be a result, conditional
     * on the parent non-union operation(s).
     *
     * @generated from protobuf enum value: REACHABLE_CONDITIONAL_RESULT = 0;
     */
    REACHABLE_CONDITIONAL_RESULT = 0,
    /**
     * *
     * DIRECT_OPERATION_RESULT indicates that the entrypoint exists solely under zero or more
     * union operations, making any reachable object also a *result* of the relation or permission.
     *
     * @generated from protobuf enum value: DIRECT_OPERATION_RESULT = 1;
     */
    DIRECT_OPERATION_RESULT = 1
}
/**
 * *
 * TypeInformation defines the allowed types for a relation.
 *
 * @generated from protobuf message core.v1.TypeInformation
 */
export interface TypeInformation {
    /**
     * *
     * allowed_direct_relations are those relation types allowed to be placed into a relation,
     * e.g. the types of subjects allowed when a relationship is written to the relation
     *
     * @generated from protobuf field: repeated core.v1.AllowedRelation allowed_direct_relations = 1;
     */
    allowedDirectRelations: AllowedRelation[];
}
/**
 * *
 * AllowedRelation is an allowed type of a relation when used as a subject.
 *
 * @generated from protobuf message core.v1.AllowedRelation
 */
export interface AllowedRelation {
    /**
     * * namespace is the full namespace path of the allowed object type
     *
     * @generated from protobuf field: string namespace = 1;
     */
    namespace: string;
    /**
     * @generated from protobuf oneof: relation_or_wildcard
     */
    relationOrWildcard: {
        oneofKind: "relation";
        /**
         * @generated from protobuf field: string relation = 3;
         */
        relation: string;
    } | {
        oneofKind: "publicWildcard";
        /**
         * @generated from protobuf field: core.v1.AllowedRelation.PublicWildcard public_wildcard = 4;
         */
        publicWildcard: AllowedRelation_PublicWildcard;
    } | {
        oneofKind: undefined;
    };
    /**
     * * source_position contains the position of the type in the source schema, if any
     *
     * @generated from protobuf field: core.v1.SourcePosition source_position = 5;
     */
    sourcePosition?: SourcePosition;
    /**
     * *
     * required_caveat defines the required caveat on this relation.
     *
     * @generated from protobuf field: core.v1.AllowedCaveat required_caveat = 6;
     */
    requiredCaveat?: AllowedCaveat;
}
/**
 * @generated from protobuf message core.v1.AllowedRelation.PublicWildcard
 */
export interface AllowedRelation_PublicWildcard {
}
/**
 * *
 * AllowedCaveat is an allowed caveat of a relation.
 *
 * @generated from protobuf message core.v1.AllowedCaveat
 */
export interface AllowedCaveat {
    /**
     * *
     * caveat_name is the name of the allowed caveat.
     *
     * @generated from protobuf field: string caveat_name = 1;
     */
    caveatName: string;
}
/**
 * @generated from protobuf message core.v1.UsersetRewrite
 */
export interface UsersetRewrite {
    /**
     * @generated from protobuf oneof: rewrite_operation
     */
    rewriteOperation: {
        oneofKind: "union";
        /**
         * @generated from protobuf field: core.v1.SetOperation union = 1;
         */
        union: SetOperation;
    } | {
        oneofKind: "intersection";
        /**
         * @generated from protobuf field: core.v1.SetOperation intersection = 2;
         */
        intersection: SetOperation;
    } | {
        oneofKind: "exclusion";
        /**
         * @generated from protobuf field: core.v1.SetOperation exclusion = 3;
         */
        exclusion: SetOperation;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: core.v1.SourcePosition source_position = 4;
     */
    sourcePosition?: SourcePosition;
}
/**
 * @generated from protobuf message core.v1.SetOperation
 */
export interface SetOperation {
    /**
     * @generated from protobuf field: repeated core.v1.SetOperation.Child child = 1;
     */
    child: SetOperation_Child[];
}
/**
 * @generated from protobuf message core.v1.SetOperation.Child
 */
export interface SetOperation_Child {
    /**
     * @generated from protobuf oneof: child_type
     */
    childType: {
        oneofKind: "This";
        /**
         * @generated from protobuf field: core.v1.SetOperation.Child.This _this = 1;
         */
        This: SetOperation_Child_This;
    } | {
        oneofKind: "computedUserset";
        /**
         * @generated from protobuf field: core.v1.ComputedUserset computed_userset = 2;
         */
        computedUserset: ComputedUserset;
    } | {
        oneofKind: "tupleToUserset";
        /**
         * @generated from protobuf field: core.v1.TupleToUserset tuple_to_userset = 3;
         */
        tupleToUserset: TupleToUserset;
    } | {
        oneofKind: "usersetRewrite";
        /**
         * @generated from protobuf field: core.v1.UsersetRewrite userset_rewrite = 4;
         */
        usersetRewrite: UsersetRewrite;
    } | {
        oneofKind: "functionedTupleToUserset";
        /**
         * @generated from protobuf field: core.v1.FunctionedTupleToUserset functioned_tuple_to_userset = 8;
         */
        functionedTupleToUserset: FunctionedTupleToUserset;
    } | {
        oneofKind: "Nil";
        /**
         * @generated from protobuf field: core.v1.SetOperation.Child.Nil _nil = 6;
         */
        Nil: SetOperation_Child_Nil;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: core.v1.SourcePosition source_position = 5;
     */
    sourcePosition?: SourcePosition;
    /**
     * *
     * operation_path (if specified) is the *unique* ID for the set operation in the permission
     * definition. It is a heirarchy representing the position of the operation under its parent
     * operation. For example, the operation path of an operation which is the third child of the
     * fourth top-level operation, will be `3,2`.
     *
     * @generated from protobuf field: repeated uint32 operation_path = 7;
     */
    operationPath: number[];
}
/**
 * @generated from protobuf message core.v1.SetOperation.Child.This
 */
export interface SetOperation_Child_This {
}
/**
 * @generated from protobuf message core.v1.SetOperation.Child.Nil
 */
export interface SetOperation_Child_Nil {
}
/**
 * @generated from protobuf message core.v1.TupleToUserset
 */
export interface TupleToUserset {
    /**
     * @generated from protobuf field: core.v1.TupleToUserset.Tupleset tupleset = 1;
     */
    tupleset?: TupleToUserset_Tupleset;
    /**
     * @generated from protobuf field: core.v1.ComputedUserset computed_userset = 2;
     */
    computedUserset?: ComputedUserset;
    /**
     * @generated from protobuf field: core.v1.SourcePosition source_position = 3;
     */
    sourcePosition?: SourcePosition;
}
/**
 * @generated from protobuf message core.v1.TupleToUserset.Tupleset
 */
export interface TupleToUserset_Tupleset {
    /**
     * @generated from protobuf field: string relation = 1;
     */
    relation: string;
}
/**
 * @generated from protobuf message core.v1.FunctionedTupleToUserset
 */
export interface FunctionedTupleToUserset {
    /**
     * @generated from protobuf field: core.v1.FunctionedTupleToUserset.Function function = 1;
     */
    function: FunctionedTupleToUserset_Function;
    /**
     * @generated from protobuf field: core.v1.FunctionedTupleToUserset.Tupleset tupleset = 2;
     */
    tupleset?: FunctionedTupleToUserset_Tupleset;
    /**
     * @generated from protobuf field: core.v1.ComputedUserset computed_userset = 3;
     */
    computedUserset?: ComputedUserset;
    /**
     * @generated from protobuf field: core.v1.SourcePosition source_position = 4;
     */
    sourcePosition?: SourcePosition;
}
/**
 * @generated from protobuf message core.v1.FunctionedTupleToUserset.Tupleset
 */
export interface FunctionedTupleToUserset_Tupleset {
    /**
     * @generated from protobuf field: string relation = 1;
     */
    relation: string;
}
/**
 * @generated from protobuf enum core.v1.FunctionedTupleToUserset.Function
 */
export enum FunctionedTupleToUserset_Function {
    /**
     * @generated from protobuf enum value: FUNCTION_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: FUNCTION_ANY = 1;
     */
    ANY = 1,
    /**
     * @generated from protobuf enum value: FUNCTION_ALL = 2;
     */
    ALL = 2
}
/**
 * @generated from protobuf message core.v1.ComputedUserset
 */
export interface ComputedUserset {
    /**
     * @generated from protobuf field: core.v1.ComputedUserset.Object object = 1;
     */
    object: ComputedUserset_Object;
    /**
     * @generated from protobuf field: string relation = 2;
     */
    relation: string;
    /**
     * @generated from protobuf field: core.v1.SourcePosition source_position = 3;
     */
    sourcePosition?: SourcePosition;
}
/**
 * @generated from protobuf enum core.v1.ComputedUserset.Object
 */
export enum ComputedUserset_Object {
    /**
     * @generated from protobuf enum value: TUPLE_OBJECT = 0;
     */
    TUPLE_OBJECT = 0,
    /**
     * @generated from protobuf enum value: TUPLE_USERSET_OBJECT = 1;
     */
    TUPLE_USERSET_OBJECT = 1
}
/**
 * @generated from protobuf message core.v1.SourcePosition
 */
export interface SourcePosition {
    /**
     * @generated from protobuf field: uint64 zero_indexed_line_number = 1;
     */
    zeroIndexedLineNumber: string;
    /**
     * @generated from protobuf field: uint64 zero_indexed_column_position = 2;
     */
    zeroIndexedColumnPosition: string;
}
/**
 * @generated from protobuf message core.v1.CaveatExpression
 */
export interface CaveatExpression {
    /**
     * @generated from protobuf oneof: operation_or_caveat
     */
    operationOrCaveat: {
        oneofKind: "operation";
        /**
         * @generated from protobuf field: core.v1.CaveatOperation operation = 1;
         */
        operation: CaveatOperation;
    } | {
        oneofKind: "caveat";
        /**
         * @generated from protobuf field: core.v1.ContextualizedCaveat caveat = 2;
         */
        caveat: ContextualizedCaveat;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message core.v1.CaveatOperation
 */
export interface CaveatOperation {
    /**
     * @generated from protobuf field: core.v1.CaveatOperation.Operation op = 1;
     */
    op: CaveatOperation_Operation;
    /**
     * @generated from protobuf field: repeated core.v1.CaveatExpression children = 2;
     */
    children: CaveatExpression[];
}
/**
 * @generated from protobuf enum core.v1.CaveatOperation.Operation
 */
export enum CaveatOperation_Operation {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: OR = 1;
     */
    OR = 1,
    /**
     * @generated from protobuf enum value: AND = 2;
     */
    AND = 2,
    /**
     * @generated from protobuf enum value: NOT = 3;
     */
    NOT = 3
}
/**
 * @generated from protobuf message core.v1.RelationshipFilter
 */
export interface RelationshipFilter {
    /**
     * resource_type is the *optional* resource type of the relationship.
     * NOTE: It is not prefixed with "optional_" for legacy compatibility.
     *
     * @generated from protobuf field: string resource_type = 1;
     */
    resourceType: string;
    /**
     * optional_resource_id is the *optional* resource ID of the relationship.
     * If specified, optional_resource_id_prefix cannot be specified.
     *
     * @generated from protobuf field: string optional_resource_id = 2;
     */
    optionalResourceId: string;
    /**
     * optional_resource_id_prefix is the *optional* prefix for the resource ID of the relationship.
     * If specified, optional_resource_id cannot be specified.
     *
     * @generated from protobuf field: string optional_resource_id_prefix = 5;
     */
    optionalResourceIdPrefix: string;
    /**
     * relation is the *optional* relation of the relationship.
     *
     * @generated from protobuf field: string optional_relation = 3;
     */
    optionalRelation: string;
    /**
     * optional_subject_filter is the optional filter for the subjects of the relationships.
     *
     * @generated from protobuf field: core.v1.SubjectFilter optional_subject_filter = 4;
     */
    optionalSubjectFilter?: SubjectFilter;
}
/**
 * SubjectFilter specifies a filter on the subject of a relationship.
 *
 * subject_type is required and all other fields are optional, and will not
 * impose any additional requirements if left unspecified.
 *
 * @generated from protobuf message core.v1.SubjectFilter
 */
export interface SubjectFilter {
    /**
     * @generated from protobuf field: string subject_type = 1;
     */
    subjectType: string;
    /**
     * @generated from protobuf field: string optional_subject_id = 2;
     */
    optionalSubjectId: string;
    /**
     * @generated from protobuf field: core.v1.SubjectFilter.RelationFilter optional_relation = 3;
     */
    optionalRelation?: SubjectFilter_RelationFilter;
}
/**
 * @generated from protobuf message core.v1.SubjectFilter.RelationFilter
 */
export interface SubjectFilter_RelationFilter {
    /**
     * @generated from protobuf field: string relation = 1;
     */
    relation: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class RelationTuple$Type extends MessageType<RelationTuple> {
    constructor() {
        super("core.v1.RelationTuple", [
            { no: 1, name: "resource_and_relation", kind: "message", T: () => ObjectAndRelation, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "subject", kind: "message", T: () => ObjectAndRelation, options: { "validate.rules": { message: { required: true } } } },
            { no: 3, name: "caveat", kind: "message", T: () => ContextualizedCaveat, options: { "validate.rules": { message: { required: false } } } }
        ]);
    }
    create(value?: PartialMessage<RelationTuple>): RelationTuple {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RelationTuple>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RelationTuple): RelationTuple {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* core.v1.ObjectAndRelation resource_and_relation */ 1:
                    message.resourceAndRelation = ObjectAndRelation.internalBinaryRead(reader, reader.uint32(), options, message.resourceAndRelation);
                    break;
                case /* core.v1.ObjectAndRelation subject */ 2:
                    message.subject = ObjectAndRelation.internalBinaryRead(reader, reader.uint32(), options, message.subject);
                    break;
                case /* core.v1.ContextualizedCaveat caveat */ 3:
                    message.caveat = ContextualizedCaveat.internalBinaryRead(reader, reader.uint32(), options, message.caveat);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RelationTuple, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* core.v1.ObjectAndRelation resource_and_relation = 1; */
        if (message.resourceAndRelation)
            ObjectAndRelation.internalBinaryWrite(message.resourceAndRelation, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.ObjectAndRelation subject = 2; */
        if (message.subject)
            ObjectAndRelation.internalBinaryWrite(message.subject, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.ContextualizedCaveat caveat = 3; */
        if (message.caveat)
            ContextualizedCaveat.internalBinaryWrite(message.caveat, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.RelationTuple
 */
export const RelationTuple = new RelationTuple$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContextualizedCaveat$Type extends MessageType<ContextualizedCaveat> {
    constructor() {
        super("core.v1.ContextualizedCaveat", [
            { no: 1, name: "caveat_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "128", pattern: "^(([a-zA-Z0-9_][a-zA-Z0-9/_|-]{0,127})|\\*)$" } } } },
            { no: 2, name: "context", kind: "message", T: () => Struct, options: { "validate.rules": { message: { required: false } } } }
        ]);
    }
    create(value?: PartialMessage<ContextualizedCaveat>): ContextualizedCaveat {
        const message = { caveatName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContextualizedCaveat>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContextualizedCaveat): ContextualizedCaveat {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string caveat_name */ 1:
                    message.caveatName = reader.string();
                    break;
                case /* google.protobuf.Struct context */ 2:
                    message.context = Struct.internalBinaryRead(reader, reader.uint32(), options, message.context);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContextualizedCaveat, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string caveat_name = 1; */
        if (message.caveatName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.caveatName);
        /* google.protobuf.Struct context = 2; */
        if (message.context)
            Struct.internalBinaryWrite(message.context, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.ContextualizedCaveat
 */
export const ContextualizedCaveat = new ContextualizedCaveat$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CaveatDefinition$Type extends MessageType<CaveatDefinition> {
    constructor() {
        super("core.v1.CaveatDefinition", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "128", pattern: "^(([a-zA-Z0-9_][a-zA-Z0-9/_|-]{0,127})|\\*)$" } } } },
            { no: 2, name: "serialized_expression", kind: "scalar", T: 12 /*ScalarType.BYTES*/, options: { "validate.rules": { bytes: { minLen: "0", maxLen: "4096" } } } },
            { no: 3, name: "parameter_types", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => CaveatTypeReference }, options: { "validate.rules": { map: { minPairs: "1", maxPairs: "20" } } } },
            { no: 4, name: "metadata", kind: "message", T: () => Metadata },
            { no: 5, name: "source_position", kind: "message", T: () => SourcePosition }
        ]);
    }
    create(value?: PartialMessage<CaveatDefinition>): CaveatDefinition {
        const message = { name: "", serializedExpression: new Uint8Array(0), parameterTypes: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CaveatDefinition>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CaveatDefinition): CaveatDefinition {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* bytes serialized_expression */ 2:
                    message.serializedExpression = reader.bytes();
                    break;
                case /* map<string, core.v1.CaveatTypeReference> parameter_types */ 3:
                    this.binaryReadMap3(message.parameterTypes, reader, options);
                    break;
                case /* core.v1.Metadata metadata */ 4:
                    message.metadata = Metadata.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                case /* core.v1.SourcePosition source_position */ 5:
                    message.sourcePosition = SourcePosition.internalBinaryRead(reader, reader.uint32(), options, message.sourcePosition);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: CaveatDefinition["parameterTypes"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CaveatDefinition["parameterTypes"] | undefined, val: CaveatDefinition["parameterTypes"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = CaveatTypeReference.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field core.v1.CaveatDefinition.parameter_types");
            }
        }
        map[key ?? ""] = val ?? CaveatTypeReference.create();
    }
    internalBinaryWrite(message: CaveatDefinition, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* bytes serialized_expression = 2; */
        if (message.serializedExpression.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.serializedExpression);
        /* map<string, core.v1.CaveatTypeReference> parameter_types = 3; */
        for (let k of Object.keys(message.parameterTypes)) {
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            CaveatTypeReference.internalBinaryWrite(message.parameterTypes[k], writer, options);
            writer.join().join();
        }
        /* core.v1.Metadata metadata = 4; */
        if (message.metadata)
            Metadata.internalBinaryWrite(message.metadata, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.SourcePosition source_position = 5; */
        if (message.sourcePosition)
            SourcePosition.internalBinaryWrite(message.sourcePosition, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.CaveatDefinition
 */
export const CaveatDefinition = new CaveatDefinition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CaveatTypeReference$Type extends MessageType<CaveatTypeReference> {
    constructor() {
        super("core.v1.CaveatTypeReference", [
            { no: 1, name: "type_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "child_types", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CaveatTypeReference, options: { "validate.rules": { repeated: { minItems: "0", maxItems: "1" } } } }
        ]);
    }
    create(value?: PartialMessage<CaveatTypeReference>): CaveatTypeReference {
        const message = { typeName: "", childTypes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CaveatTypeReference>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CaveatTypeReference): CaveatTypeReference {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type_name */ 1:
                    message.typeName = reader.string();
                    break;
                case /* repeated core.v1.CaveatTypeReference child_types */ 2:
                    message.childTypes.push(CaveatTypeReference.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CaveatTypeReference, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type_name = 1; */
        if (message.typeName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.typeName);
        /* repeated core.v1.CaveatTypeReference child_types = 2; */
        for (let i = 0; i < message.childTypes.length; i++)
            CaveatTypeReference.internalBinaryWrite(message.childTypes[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.CaveatTypeReference
 */
export const CaveatTypeReference = new CaveatTypeReference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ObjectAndRelation$Type extends MessageType<ObjectAndRelation> {
    constructor() {
        super("core.v1.ObjectAndRelation", [
            { no: 1, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "128", pattern: "^([a-z][a-z0-9_]{1,61}[a-z0-9]/)*[a-z][a-z0-9_]{1,62}[a-z0-9]$" } } } },
            { no: 2, name: "object_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "1024", pattern: "^(([a-zA-Z0-9/_|\\-=+]{1,})|\\*)$" } } } },
            { no: 3, name: "relation", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "64", pattern: "^(\\.\\.\\.|[a-z][a-z0-9_]{1,62}[a-z0-9])$" } } } }
        ]);
    }
    create(value?: PartialMessage<ObjectAndRelation>): ObjectAndRelation {
        const message = { namespace: "", objectId: "", relation: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ObjectAndRelation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ObjectAndRelation): ObjectAndRelation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string namespace */ 1:
                    message.namespace = reader.string();
                    break;
                case /* string object_id */ 2:
                    message.objectId = reader.string();
                    break;
                case /* string relation */ 3:
                    message.relation = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ObjectAndRelation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string namespace = 1; */
        if (message.namespace !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.namespace);
        /* string object_id = 2; */
        if (message.objectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.objectId);
        /* string relation = 3; */
        if (message.relation !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.relation);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.ObjectAndRelation
 */
export const ObjectAndRelation = new ObjectAndRelation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RelationReference$Type extends MessageType<RelationReference> {
    constructor() {
        super("core.v1.RelationReference", [
            { no: 1, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "128", pattern: "^([a-z][a-z0-9_]{1,61}[a-z0-9]/)*[a-z][a-z0-9_]{1,62}[a-z0-9]$" } } } },
            { no: 3, name: "relation", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "64", pattern: "^(\\.\\.\\.|[a-z][a-z0-9_]{1,62}[a-z0-9])$" } } } }
        ]);
    }
    create(value?: PartialMessage<RelationReference>): RelationReference {
        const message = { namespace: "", relation: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RelationReference>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RelationReference): RelationReference {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string namespace */ 1:
                    message.namespace = reader.string();
                    break;
                case /* string relation */ 3:
                    message.relation = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RelationReference, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string namespace = 1; */
        if (message.namespace !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.namespace);
        /* string relation = 3; */
        if (message.relation !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.relation);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.RelationReference
 */
export const RelationReference = new RelationReference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Zookie$Type extends MessageType<Zookie> {
    constructor() {
        super("core.v1.Zookie", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minBytes: "1" } } } }
        ]);
    }
    create(value?: PartialMessage<Zookie>): Zookie {
        const message = { token: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Zookie>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Zookie): Zookie {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Zookie, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.Zookie
 */
export const Zookie = new Zookie$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RelationTupleUpdate$Type extends MessageType<RelationTupleUpdate> {
    constructor() {
        super("core.v1.RelationTupleUpdate", [
            { no: 1, name: "operation", kind: "enum", T: () => ["core.v1.RelationTupleUpdate.Operation", RelationTupleUpdate_Operation], options: { "validate.rules": { enum: { definedOnly: true } } } },
            { no: 2, name: "tuple", kind: "message", T: () => RelationTuple, options: { "validate.rules": { message: { required: true } } } }
        ]);
    }
    create(value?: PartialMessage<RelationTupleUpdate>): RelationTupleUpdate {
        const message = { operation: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RelationTupleUpdate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RelationTupleUpdate): RelationTupleUpdate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* core.v1.RelationTupleUpdate.Operation operation */ 1:
                    message.operation = reader.int32();
                    break;
                case /* core.v1.RelationTuple tuple */ 2:
                    message.tuple = RelationTuple.internalBinaryRead(reader, reader.uint32(), options, message.tuple);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RelationTupleUpdate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* core.v1.RelationTupleUpdate.Operation operation = 1; */
        if (message.operation !== 0)
            writer.tag(1, WireType.Varint).int32(message.operation);
        /* core.v1.RelationTuple tuple = 2; */
        if (message.tuple)
            RelationTuple.internalBinaryWrite(message.tuple, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.RelationTupleUpdate
 */
export const RelationTupleUpdate = new RelationTupleUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RelationTupleTreeNode$Type extends MessageType<RelationTupleTreeNode> {
    constructor() {
        super("core.v1.RelationTupleTreeNode", [
            { no: 1, name: "intermediate_node", kind: "message", oneof: "nodeType", T: () => SetOperationUserset },
            { no: 2, name: "leaf_node", kind: "message", oneof: "nodeType", T: () => DirectSubjects },
            { no: 3, name: "expanded", kind: "message", T: () => ObjectAndRelation },
            { no: 4, name: "caveat_expression", kind: "message", T: () => CaveatExpression }
        ]);
    }
    create(value?: PartialMessage<RelationTupleTreeNode>): RelationTupleTreeNode {
        const message = { nodeType: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RelationTupleTreeNode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RelationTupleTreeNode): RelationTupleTreeNode {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* core.v1.SetOperationUserset intermediate_node */ 1:
                    message.nodeType = {
                        oneofKind: "intermediateNode",
                        intermediateNode: SetOperationUserset.internalBinaryRead(reader, reader.uint32(), options, (message.nodeType as any).intermediateNode)
                    };
                    break;
                case /* core.v1.DirectSubjects leaf_node */ 2:
                    message.nodeType = {
                        oneofKind: "leafNode",
                        leafNode: DirectSubjects.internalBinaryRead(reader, reader.uint32(), options, (message.nodeType as any).leafNode)
                    };
                    break;
                case /* core.v1.ObjectAndRelation expanded */ 3:
                    message.expanded = ObjectAndRelation.internalBinaryRead(reader, reader.uint32(), options, message.expanded);
                    break;
                case /* core.v1.CaveatExpression caveat_expression */ 4:
                    message.caveatExpression = CaveatExpression.internalBinaryRead(reader, reader.uint32(), options, message.caveatExpression);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RelationTupleTreeNode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* core.v1.SetOperationUserset intermediate_node = 1; */
        if (message.nodeType.oneofKind === "intermediateNode")
            SetOperationUserset.internalBinaryWrite(message.nodeType.intermediateNode, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.DirectSubjects leaf_node = 2; */
        if (message.nodeType.oneofKind === "leafNode")
            DirectSubjects.internalBinaryWrite(message.nodeType.leafNode, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.ObjectAndRelation expanded = 3; */
        if (message.expanded)
            ObjectAndRelation.internalBinaryWrite(message.expanded, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.CaveatExpression caveat_expression = 4; */
        if (message.caveatExpression)
            CaveatExpression.internalBinaryWrite(message.caveatExpression, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.RelationTupleTreeNode
 */
export const RelationTupleTreeNode = new RelationTupleTreeNode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetOperationUserset$Type extends MessageType<SetOperationUserset> {
    constructor() {
        super("core.v1.SetOperationUserset", [
            { no: 1, name: "operation", kind: "enum", T: () => ["core.v1.SetOperationUserset.Operation", SetOperationUserset_Operation] },
            { no: 2, name: "child_nodes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RelationTupleTreeNode }
        ]);
    }
    create(value?: PartialMessage<SetOperationUserset>): SetOperationUserset {
        const message = { operation: 0, childNodes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetOperationUserset>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetOperationUserset): SetOperationUserset {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* core.v1.SetOperationUserset.Operation operation */ 1:
                    message.operation = reader.int32();
                    break;
                case /* repeated core.v1.RelationTupleTreeNode child_nodes */ 2:
                    message.childNodes.push(RelationTupleTreeNode.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetOperationUserset, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* core.v1.SetOperationUserset.Operation operation = 1; */
        if (message.operation !== 0)
            writer.tag(1, WireType.Varint).int32(message.operation);
        /* repeated core.v1.RelationTupleTreeNode child_nodes = 2; */
        for (let i = 0; i < message.childNodes.length; i++)
            RelationTupleTreeNode.internalBinaryWrite(message.childNodes[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.SetOperationUserset
 */
export const SetOperationUserset = new SetOperationUserset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DirectSubject$Type extends MessageType<DirectSubject> {
    constructor() {
        super("core.v1.DirectSubject", [
            { no: 1, name: "subject", kind: "message", T: () => ObjectAndRelation },
            { no: 2, name: "caveat_expression", kind: "message", T: () => CaveatExpression }
        ]);
    }
    create(value?: PartialMessage<DirectSubject>): DirectSubject {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DirectSubject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DirectSubject): DirectSubject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* core.v1.ObjectAndRelation subject */ 1:
                    message.subject = ObjectAndRelation.internalBinaryRead(reader, reader.uint32(), options, message.subject);
                    break;
                case /* core.v1.CaveatExpression caveat_expression */ 2:
                    message.caveatExpression = CaveatExpression.internalBinaryRead(reader, reader.uint32(), options, message.caveatExpression);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DirectSubject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* core.v1.ObjectAndRelation subject = 1; */
        if (message.subject)
            ObjectAndRelation.internalBinaryWrite(message.subject, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.CaveatExpression caveat_expression = 2; */
        if (message.caveatExpression)
            CaveatExpression.internalBinaryWrite(message.caveatExpression, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.DirectSubject
 */
export const DirectSubject = new DirectSubject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DirectSubjects$Type extends MessageType<DirectSubjects> {
    constructor() {
        super("core.v1.DirectSubjects", [
            { no: 1, name: "subjects", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DirectSubject }
        ]);
    }
    create(value?: PartialMessage<DirectSubjects>): DirectSubjects {
        const message = { subjects: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DirectSubjects>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DirectSubjects): DirectSubjects {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated core.v1.DirectSubject subjects */ 1:
                    message.subjects.push(DirectSubject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DirectSubjects, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated core.v1.DirectSubject subjects = 1; */
        for (let i = 0; i < message.subjects.length; i++)
            DirectSubject.internalBinaryWrite(message.subjects[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.DirectSubjects
 */
export const DirectSubjects = new DirectSubjects$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Metadata$Type extends MessageType<Metadata> {
    constructor() {
        super("core.v1.Metadata", [
            { no: 1, name: "metadata_message", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Any, options: { "validate.rules": { repeated: { minItems: "1", items: { message: { required: true }, any: { required: true, in: ["type.googleapis.com/impl.v1.DocComment", "type.googleapis.com/impl.v1.RelationMetadata"] } } } } } }
        ]);
    }
    create(value?: PartialMessage<Metadata>): Metadata {
        const message = { metadataMessage: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Metadata>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Metadata): Metadata {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated google.protobuf.Any metadata_message */ 1:
                    message.metadataMessage.push(Any.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Metadata, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated google.protobuf.Any metadata_message = 1; */
        for (let i = 0; i < message.metadataMessage.length; i++)
            Any.internalBinaryWrite(message.metadataMessage[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.Metadata
 */
export const Metadata = new Metadata$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NamespaceDefinition$Type extends MessageType<NamespaceDefinition> {
    constructor() {
        super("core.v1.NamespaceDefinition", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "128", pattern: "^([a-z][a-z0-9_]{1,62}[a-z0-9]/)*[a-z][a-z0-9_]{1,62}[a-z0-9]$" } } } },
            { no: 2, name: "relation", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Relation },
            { no: 3, name: "metadata", kind: "message", T: () => Metadata },
            { no: 4, name: "source_position", kind: "message", T: () => SourcePosition }
        ]);
    }
    create(value?: PartialMessage<NamespaceDefinition>): NamespaceDefinition {
        const message = { name: "", relation: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NamespaceDefinition>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NamespaceDefinition): NamespaceDefinition {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* repeated core.v1.Relation relation */ 2:
                    message.relation.push(Relation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* core.v1.Metadata metadata */ 3:
                    message.metadata = Metadata.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                case /* core.v1.SourcePosition source_position */ 4:
                    message.sourcePosition = SourcePosition.internalBinaryRead(reader, reader.uint32(), options, message.sourcePosition);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NamespaceDefinition, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* repeated core.v1.Relation relation = 2; */
        for (let i = 0; i < message.relation.length; i++)
            Relation.internalBinaryWrite(message.relation[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.Metadata metadata = 3; */
        if (message.metadata)
            Metadata.internalBinaryWrite(message.metadata, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.SourcePosition source_position = 4; */
        if (message.sourcePosition)
            SourcePosition.internalBinaryWrite(message.sourcePosition, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.NamespaceDefinition
 */
export const NamespaceDefinition = new NamespaceDefinition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Relation$Type extends MessageType<Relation> {
    constructor() {
        super("core.v1.Relation", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "64", pattern: "^[a-z][a-z0-9_]{1,62}[a-z0-9]$" } } } },
            { no: 2, name: "userset_rewrite", kind: "message", T: () => UsersetRewrite },
            { no: 3, name: "type_information", kind: "message", T: () => TypeInformation },
            { no: 4, name: "metadata", kind: "message", T: () => Metadata },
            { no: 5, name: "source_position", kind: "message", T: () => SourcePosition },
            { no: 6, name: "aliasing_relation", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "canonical_cache_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Relation>): Relation {
        const message = { name: "", aliasingRelation: "", canonicalCacheKey: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Relation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Relation): Relation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* core.v1.UsersetRewrite userset_rewrite */ 2:
                    message.usersetRewrite = UsersetRewrite.internalBinaryRead(reader, reader.uint32(), options, message.usersetRewrite);
                    break;
                case /* core.v1.TypeInformation type_information */ 3:
                    message.typeInformation = TypeInformation.internalBinaryRead(reader, reader.uint32(), options, message.typeInformation);
                    break;
                case /* core.v1.Metadata metadata */ 4:
                    message.metadata = Metadata.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                case /* core.v1.SourcePosition source_position */ 5:
                    message.sourcePosition = SourcePosition.internalBinaryRead(reader, reader.uint32(), options, message.sourcePosition);
                    break;
                case /* string aliasing_relation */ 6:
                    message.aliasingRelation = reader.string();
                    break;
                case /* string canonical_cache_key */ 7:
                    message.canonicalCacheKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Relation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* core.v1.UsersetRewrite userset_rewrite = 2; */
        if (message.usersetRewrite)
            UsersetRewrite.internalBinaryWrite(message.usersetRewrite, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.TypeInformation type_information = 3; */
        if (message.typeInformation)
            TypeInformation.internalBinaryWrite(message.typeInformation, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.Metadata metadata = 4; */
        if (message.metadata)
            Metadata.internalBinaryWrite(message.metadata, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.SourcePosition source_position = 5; */
        if (message.sourcePosition)
            SourcePosition.internalBinaryWrite(message.sourcePosition, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string aliasing_relation = 6; */
        if (message.aliasingRelation !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.aliasingRelation);
        /* string canonical_cache_key = 7; */
        if (message.canonicalCacheKey !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.canonicalCacheKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.Relation
 */
export const Relation = new Relation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReachabilityGraph$Type extends MessageType<ReachabilityGraph> {
    constructor() {
        super("core.v1.ReachabilityGraph", [
            { no: 1, name: "entrypoints_by_subject_type", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => ReachabilityEntrypoints } },
            { no: 2, name: "entrypoints_by_subject_relation", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => ReachabilityEntrypoints } }
        ]);
    }
    create(value?: PartialMessage<ReachabilityGraph>): ReachabilityGraph {
        const message = { entrypointsBySubjectType: {}, entrypointsBySubjectRelation: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReachabilityGraph>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReachabilityGraph): ReachabilityGraph {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, core.v1.ReachabilityEntrypoints> entrypoints_by_subject_type */ 1:
                    this.binaryReadMap1(message.entrypointsBySubjectType, reader, options);
                    break;
                case /* map<string, core.v1.ReachabilityEntrypoints> entrypoints_by_subject_relation */ 2:
                    this.binaryReadMap2(message.entrypointsBySubjectRelation, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: ReachabilityGraph["entrypointsBySubjectType"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ReachabilityGraph["entrypointsBySubjectType"] | undefined, val: ReachabilityGraph["entrypointsBySubjectType"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = ReachabilityEntrypoints.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field core.v1.ReachabilityGraph.entrypoints_by_subject_type");
            }
        }
        map[key ?? ""] = val ?? ReachabilityEntrypoints.create();
    }
    private binaryReadMap2(map: ReachabilityGraph["entrypointsBySubjectRelation"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ReachabilityGraph["entrypointsBySubjectRelation"] | undefined, val: ReachabilityGraph["entrypointsBySubjectRelation"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = ReachabilityEntrypoints.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field core.v1.ReachabilityGraph.entrypoints_by_subject_relation");
            }
        }
        map[key ?? ""] = val ?? ReachabilityEntrypoints.create();
    }
    internalBinaryWrite(message: ReachabilityGraph, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, core.v1.ReachabilityEntrypoints> entrypoints_by_subject_type = 1; */
        for (let k of Object.keys(message.entrypointsBySubjectType)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            ReachabilityEntrypoints.internalBinaryWrite(message.entrypointsBySubjectType[k], writer, options);
            writer.join().join();
        }
        /* map<string, core.v1.ReachabilityEntrypoints> entrypoints_by_subject_relation = 2; */
        for (let k of Object.keys(message.entrypointsBySubjectRelation)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            ReachabilityEntrypoints.internalBinaryWrite(message.entrypointsBySubjectRelation[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.ReachabilityGraph
 */
export const ReachabilityGraph = new ReachabilityGraph$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReachabilityEntrypoints$Type extends MessageType<ReachabilityEntrypoints> {
    constructor() {
        super("core.v1.ReachabilityEntrypoints", [
            { no: 1, name: "entrypoints", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ReachabilityEntrypoint },
            { no: 2, name: "subject_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "subject_relation", kind: "message", T: () => RelationReference }
        ]);
    }
    create(value?: PartialMessage<ReachabilityEntrypoints>): ReachabilityEntrypoints {
        const message = { entrypoints: [], subjectType: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReachabilityEntrypoints>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReachabilityEntrypoints): ReachabilityEntrypoints {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated core.v1.ReachabilityEntrypoint entrypoints */ 1:
                    message.entrypoints.push(ReachabilityEntrypoint.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string subject_type */ 2:
                    message.subjectType = reader.string();
                    break;
                case /* core.v1.RelationReference subject_relation */ 3:
                    message.subjectRelation = RelationReference.internalBinaryRead(reader, reader.uint32(), options, message.subjectRelation);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReachabilityEntrypoints, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated core.v1.ReachabilityEntrypoint entrypoints = 1; */
        for (let i = 0; i < message.entrypoints.length; i++)
            ReachabilityEntrypoint.internalBinaryWrite(message.entrypoints[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string subject_type = 2; */
        if (message.subjectType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.subjectType);
        /* core.v1.RelationReference subject_relation = 3; */
        if (message.subjectRelation)
            RelationReference.internalBinaryWrite(message.subjectRelation, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.ReachabilityEntrypoints
 */
export const ReachabilityEntrypoints = new ReachabilityEntrypoints$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReachabilityEntrypoint$Type extends MessageType<ReachabilityEntrypoint> {
    constructor() {
        super("core.v1.ReachabilityEntrypoint", [
            { no: 1, name: "kind", kind: "enum", T: () => ["core.v1.ReachabilityEntrypoint.ReachabilityEntrypointKind", ReachabilityEntrypoint_ReachabilityEntrypointKind] },
            { no: 2, name: "target_relation", kind: "message", T: () => RelationReference },
            { no: 4, name: "result_status", kind: "enum", T: () => ["core.v1.ReachabilityEntrypoint.EntrypointResultStatus", ReachabilityEntrypoint_EntrypointResultStatus] },
            { no: 5, name: "tupleset_relation", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "computed_userset_relation", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReachabilityEntrypoint>): ReachabilityEntrypoint {
        const message = { kind: 0, resultStatus: 0, tuplesetRelation: "", computedUsersetRelation: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReachabilityEntrypoint>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReachabilityEntrypoint): ReachabilityEntrypoint {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* core.v1.ReachabilityEntrypoint.ReachabilityEntrypointKind kind */ 1:
                    message.kind = reader.int32();
                    break;
                case /* core.v1.RelationReference target_relation */ 2:
                    message.targetRelation = RelationReference.internalBinaryRead(reader, reader.uint32(), options, message.targetRelation);
                    break;
                case /* core.v1.ReachabilityEntrypoint.EntrypointResultStatus result_status */ 4:
                    message.resultStatus = reader.int32();
                    break;
                case /* string tupleset_relation */ 5:
                    message.tuplesetRelation = reader.string();
                    break;
                case /* string computed_userset_relation */ 6:
                    message.computedUsersetRelation = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReachabilityEntrypoint, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* core.v1.ReachabilityEntrypoint.ReachabilityEntrypointKind kind = 1; */
        if (message.kind !== 0)
            writer.tag(1, WireType.Varint).int32(message.kind);
        /* core.v1.RelationReference target_relation = 2; */
        if (message.targetRelation)
            RelationReference.internalBinaryWrite(message.targetRelation, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.ReachabilityEntrypoint.EntrypointResultStatus result_status = 4; */
        if (message.resultStatus !== 0)
            writer.tag(4, WireType.Varint).int32(message.resultStatus);
        /* string tupleset_relation = 5; */
        if (message.tuplesetRelation !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.tuplesetRelation);
        /* string computed_userset_relation = 6; */
        if (message.computedUsersetRelation !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.computedUsersetRelation);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.ReachabilityEntrypoint
 */
export const ReachabilityEntrypoint = new ReachabilityEntrypoint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TypeInformation$Type extends MessageType<TypeInformation> {
    constructor() {
        super("core.v1.TypeInformation", [
            { no: 1, name: "allowed_direct_relations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AllowedRelation }
        ]);
    }
    create(value?: PartialMessage<TypeInformation>): TypeInformation {
        const message = { allowedDirectRelations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TypeInformation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TypeInformation): TypeInformation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated core.v1.AllowedRelation allowed_direct_relations */ 1:
                    message.allowedDirectRelations.push(AllowedRelation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TypeInformation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated core.v1.AllowedRelation allowed_direct_relations = 1; */
        for (let i = 0; i < message.allowedDirectRelations.length; i++)
            AllowedRelation.internalBinaryWrite(message.allowedDirectRelations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.TypeInformation
 */
export const TypeInformation = new TypeInformation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AllowedRelation$Type extends MessageType<AllowedRelation> {
    constructor() {
        super("core.v1.AllowedRelation", [
            { no: 1, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "128", pattern: "^([a-z][a-z0-9_]{1,61}[a-z0-9]/)*[a-z][a-z0-9_]{1,62}[a-z0-9]$" } } } },
            { no: 3, name: "relation", kind: "scalar", oneof: "relationOrWildcard", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "64", pattern: "^(\\.\\.\\.|[a-z][a-z0-9_]{1,62}[a-z0-9])$" } } } },
            { no: 4, name: "public_wildcard", kind: "message", oneof: "relationOrWildcard", T: () => AllowedRelation_PublicWildcard },
            { no: 5, name: "source_position", kind: "message", T: () => SourcePosition },
            { no: 6, name: "required_caveat", kind: "message", T: () => AllowedCaveat }
        ]);
    }
    create(value?: PartialMessage<AllowedRelation>): AllowedRelation {
        const message = { namespace: "", relationOrWildcard: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AllowedRelation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllowedRelation): AllowedRelation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string namespace */ 1:
                    message.namespace = reader.string();
                    break;
                case /* string relation */ 3:
                    message.relationOrWildcard = {
                        oneofKind: "relation",
                        relation: reader.string()
                    };
                    break;
                case /* core.v1.AllowedRelation.PublicWildcard public_wildcard */ 4:
                    message.relationOrWildcard = {
                        oneofKind: "publicWildcard",
                        publicWildcard: AllowedRelation_PublicWildcard.internalBinaryRead(reader, reader.uint32(), options, (message.relationOrWildcard as any).publicWildcard)
                    };
                    break;
                case /* core.v1.SourcePosition source_position */ 5:
                    message.sourcePosition = SourcePosition.internalBinaryRead(reader, reader.uint32(), options, message.sourcePosition);
                    break;
                case /* core.v1.AllowedCaveat required_caveat */ 6:
                    message.requiredCaveat = AllowedCaveat.internalBinaryRead(reader, reader.uint32(), options, message.requiredCaveat);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AllowedRelation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string namespace = 1; */
        if (message.namespace !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.namespace);
        /* string relation = 3; */
        if (message.relationOrWildcard.oneofKind === "relation")
            writer.tag(3, WireType.LengthDelimited).string(message.relationOrWildcard.relation);
        /* core.v1.AllowedRelation.PublicWildcard public_wildcard = 4; */
        if (message.relationOrWildcard.oneofKind === "publicWildcard")
            AllowedRelation_PublicWildcard.internalBinaryWrite(message.relationOrWildcard.publicWildcard, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.SourcePosition source_position = 5; */
        if (message.sourcePosition)
            SourcePosition.internalBinaryWrite(message.sourcePosition, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.AllowedCaveat required_caveat = 6; */
        if (message.requiredCaveat)
            AllowedCaveat.internalBinaryWrite(message.requiredCaveat, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.AllowedRelation
 */
export const AllowedRelation = new AllowedRelation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AllowedRelation_PublicWildcard$Type extends MessageType<AllowedRelation_PublicWildcard> {
    constructor() {
        super("core.v1.AllowedRelation.PublicWildcard", []);
    }
    create(value?: PartialMessage<AllowedRelation_PublicWildcard>): AllowedRelation_PublicWildcard {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AllowedRelation_PublicWildcard>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllowedRelation_PublicWildcard): AllowedRelation_PublicWildcard {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AllowedRelation_PublicWildcard, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.AllowedRelation.PublicWildcard
 */
export const AllowedRelation_PublicWildcard = new AllowedRelation_PublicWildcard$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AllowedCaveat$Type extends MessageType<AllowedCaveat> {
    constructor() {
        super("core.v1.AllowedCaveat", [
            { no: 1, name: "caveat_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AllowedCaveat>): AllowedCaveat {
        const message = { caveatName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AllowedCaveat>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AllowedCaveat): AllowedCaveat {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string caveat_name */ 1:
                    message.caveatName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AllowedCaveat, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string caveat_name = 1; */
        if (message.caveatName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.caveatName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.AllowedCaveat
 */
export const AllowedCaveat = new AllowedCaveat$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UsersetRewrite$Type extends MessageType<UsersetRewrite> {
    constructor() {
        super("core.v1.UsersetRewrite", [
            { no: 1, name: "union", kind: "message", oneof: "rewriteOperation", T: () => SetOperation, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "intersection", kind: "message", oneof: "rewriteOperation", T: () => SetOperation, options: { "validate.rules": { message: { required: true } } } },
            { no: 3, name: "exclusion", kind: "message", oneof: "rewriteOperation", T: () => SetOperation, options: { "validate.rules": { message: { required: true } } } },
            { no: 4, name: "source_position", kind: "message", T: () => SourcePosition }
        ]);
    }
    create(value?: PartialMessage<UsersetRewrite>): UsersetRewrite {
        const message = { rewriteOperation: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UsersetRewrite>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UsersetRewrite): UsersetRewrite {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* core.v1.SetOperation union */ 1:
                    message.rewriteOperation = {
                        oneofKind: "union",
                        union: SetOperation.internalBinaryRead(reader, reader.uint32(), options, (message.rewriteOperation as any).union)
                    };
                    break;
                case /* core.v1.SetOperation intersection */ 2:
                    message.rewriteOperation = {
                        oneofKind: "intersection",
                        intersection: SetOperation.internalBinaryRead(reader, reader.uint32(), options, (message.rewriteOperation as any).intersection)
                    };
                    break;
                case /* core.v1.SetOperation exclusion */ 3:
                    message.rewriteOperation = {
                        oneofKind: "exclusion",
                        exclusion: SetOperation.internalBinaryRead(reader, reader.uint32(), options, (message.rewriteOperation as any).exclusion)
                    };
                    break;
                case /* core.v1.SourcePosition source_position */ 4:
                    message.sourcePosition = SourcePosition.internalBinaryRead(reader, reader.uint32(), options, message.sourcePosition);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UsersetRewrite, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* core.v1.SetOperation union = 1; */
        if (message.rewriteOperation.oneofKind === "union")
            SetOperation.internalBinaryWrite(message.rewriteOperation.union, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.SetOperation intersection = 2; */
        if (message.rewriteOperation.oneofKind === "intersection")
            SetOperation.internalBinaryWrite(message.rewriteOperation.intersection, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.SetOperation exclusion = 3; */
        if (message.rewriteOperation.oneofKind === "exclusion")
            SetOperation.internalBinaryWrite(message.rewriteOperation.exclusion, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.SourcePosition source_position = 4; */
        if (message.sourcePosition)
            SourcePosition.internalBinaryWrite(message.sourcePosition, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.UsersetRewrite
 */
export const UsersetRewrite = new UsersetRewrite$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetOperation$Type extends MessageType<SetOperation> {
    constructor() {
        super("core.v1.SetOperation", [
            { no: 1, name: "child", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SetOperation_Child, options: { "validate.rules": { repeated: { minItems: "1", items: { message: { required: true } } } } } }
        ]);
    }
    create(value?: PartialMessage<SetOperation>): SetOperation {
        const message = { child: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetOperation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetOperation): SetOperation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated core.v1.SetOperation.Child child */ 1:
                    message.child.push(SetOperation_Child.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetOperation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated core.v1.SetOperation.Child child = 1; */
        for (let i = 0; i < message.child.length; i++)
            SetOperation_Child.internalBinaryWrite(message.child[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.SetOperation
 */
export const SetOperation = new SetOperation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetOperation_Child$Type extends MessageType<SetOperation_Child> {
    constructor() {
        super("core.v1.SetOperation.Child", [
            { no: 1, name: "_this", kind: "message", oneof: "childType", T: () => SetOperation_Child_This },
            { no: 2, name: "computed_userset", kind: "message", oneof: "childType", T: () => ComputedUserset, options: { "validate.rules": { message: { required: true } } } },
            { no: 3, name: "tuple_to_userset", kind: "message", oneof: "childType", T: () => TupleToUserset, options: { "validate.rules": { message: { required: true } } } },
            { no: 4, name: "userset_rewrite", kind: "message", oneof: "childType", T: () => UsersetRewrite, options: { "validate.rules": { message: { required: true } } } },
            { no: 8, name: "functioned_tuple_to_userset", kind: "message", oneof: "childType", T: () => FunctionedTupleToUserset, options: { "validate.rules": { message: { required: true } } } },
            { no: 6, name: "_nil", kind: "message", oneof: "childType", T: () => SetOperation_Child_Nil },
            { no: 5, name: "source_position", kind: "message", T: () => SourcePosition },
            { no: 7, name: "operation_path", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<SetOperation_Child>): SetOperation_Child {
        const message = { childType: { oneofKind: undefined }, operationPath: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetOperation_Child>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetOperation_Child): SetOperation_Child {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* core.v1.SetOperation.Child.This _this */ 1:
                    message.childType = {
                        oneofKind: "This",
                        This: SetOperation_Child_This.internalBinaryRead(reader, reader.uint32(), options, (message.childType as any).This)
                    };
                    break;
                case /* core.v1.ComputedUserset computed_userset */ 2:
                    message.childType = {
                        oneofKind: "computedUserset",
                        computedUserset: ComputedUserset.internalBinaryRead(reader, reader.uint32(), options, (message.childType as any).computedUserset)
                    };
                    break;
                case /* core.v1.TupleToUserset tuple_to_userset */ 3:
                    message.childType = {
                        oneofKind: "tupleToUserset",
                        tupleToUserset: TupleToUserset.internalBinaryRead(reader, reader.uint32(), options, (message.childType as any).tupleToUserset)
                    };
                    break;
                case /* core.v1.UsersetRewrite userset_rewrite */ 4:
                    message.childType = {
                        oneofKind: "usersetRewrite",
                        usersetRewrite: UsersetRewrite.internalBinaryRead(reader, reader.uint32(), options, (message.childType as any).usersetRewrite)
                    };
                    break;
                case /* core.v1.FunctionedTupleToUserset functioned_tuple_to_userset */ 8:
                    message.childType = {
                        oneofKind: "functionedTupleToUserset",
                        functionedTupleToUserset: FunctionedTupleToUserset.internalBinaryRead(reader, reader.uint32(), options, (message.childType as any).functionedTupleToUserset)
                    };
                    break;
                case /* core.v1.SetOperation.Child.Nil _nil */ 6:
                    message.childType = {
                        oneofKind: "Nil",
                        Nil: SetOperation_Child_Nil.internalBinaryRead(reader, reader.uint32(), options, (message.childType as any).Nil)
                    };
                    break;
                case /* core.v1.SourcePosition source_position */ 5:
                    message.sourcePosition = SourcePosition.internalBinaryRead(reader, reader.uint32(), options, message.sourcePosition);
                    break;
                case /* repeated uint32 operation_path */ 7:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.operationPath.push(reader.uint32());
                    else
                        message.operationPath.push(reader.uint32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetOperation_Child, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* core.v1.SetOperation.Child.This _this = 1; */
        if (message.childType.oneofKind === "This")
            SetOperation_Child_This.internalBinaryWrite(message.childType.This, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.ComputedUserset computed_userset = 2; */
        if (message.childType.oneofKind === "computedUserset")
            ComputedUserset.internalBinaryWrite(message.childType.computedUserset, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.TupleToUserset tuple_to_userset = 3; */
        if (message.childType.oneofKind === "tupleToUserset")
            TupleToUserset.internalBinaryWrite(message.childType.tupleToUserset, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.UsersetRewrite userset_rewrite = 4; */
        if (message.childType.oneofKind === "usersetRewrite")
            UsersetRewrite.internalBinaryWrite(message.childType.usersetRewrite, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.FunctionedTupleToUserset functioned_tuple_to_userset = 8; */
        if (message.childType.oneofKind === "functionedTupleToUserset")
            FunctionedTupleToUserset.internalBinaryWrite(message.childType.functionedTupleToUserset, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.SetOperation.Child.Nil _nil = 6; */
        if (message.childType.oneofKind === "Nil")
            SetOperation_Child_Nil.internalBinaryWrite(message.childType.Nil, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.SourcePosition source_position = 5; */
        if (message.sourcePosition)
            SourcePosition.internalBinaryWrite(message.sourcePosition, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated uint32 operation_path = 7; */
        if (message.operationPath.length) {
            writer.tag(7, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.operationPath.length; i++)
                writer.uint32(message.operationPath[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.SetOperation.Child
 */
export const SetOperation_Child = new SetOperation_Child$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetOperation_Child_This$Type extends MessageType<SetOperation_Child_This> {
    constructor() {
        super("core.v1.SetOperation.Child.This", []);
    }
    create(value?: PartialMessage<SetOperation_Child_This>): SetOperation_Child_This {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetOperation_Child_This>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetOperation_Child_This): SetOperation_Child_This {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetOperation_Child_This, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.SetOperation.Child.This
 */
export const SetOperation_Child_This = new SetOperation_Child_This$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetOperation_Child_Nil$Type extends MessageType<SetOperation_Child_Nil> {
    constructor() {
        super("core.v1.SetOperation.Child.Nil", []);
    }
    create(value?: PartialMessage<SetOperation_Child_Nil>): SetOperation_Child_Nil {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetOperation_Child_Nil>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetOperation_Child_Nil): SetOperation_Child_Nil {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetOperation_Child_Nil, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.SetOperation.Child.Nil
 */
export const SetOperation_Child_Nil = new SetOperation_Child_Nil$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TupleToUserset$Type extends MessageType<TupleToUserset> {
    constructor() {
        super("core.v1.TupleToUserset", [
            { no: 1, name: "tupleset", kind: "message", T: () => TupleToUserset_Tupleset, options: { "validate.rules": { message: { required: true } } } },
            { no: 2, name: "computed_userset", kind: "message", T: () => ComputedUserset, options: { "validate.rules": { message: { required: true } } } },
            { no: 3, name: "source_position", kind: "message", T: () => SourcePosition }
        ]);
    }
    create(value?: PartialMessage<TupleToUserset>): TupleToUserset {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TupleToUserset>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TupleToUserset): TupleToUserset {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* core.v1.TupleToUserset.Tupleset tupleset */ 1:
                    message.tupleset = TupleToUserset_Tupleset.internalBinaryRead(reader, reader.uint32(), options, message.tupleset);
                    break;
                case /* core.v1.ComputedUserset computed_userset */ 2:
                    message.computedUserset = ComputedUserset.internalBinaryRead(reader, reader.uint32(), options, message.computedUserset);
                    break;
                case /* core.v1.SourcePosition source_position */ 3:
                    message.sourcePosition = SourcePosition.internalBinaryRead(reader, reader.uint32(), options, message.sourcePosition);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TupleToUserset, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* core.v1.TupleToUserset.Tupleset tupleset = 1; */
        if (message.tupleset)
            TupleToUserset_Tupleset.internalBinaryWrite(message.tupleset, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.ComputedUserset computed_userset = 2; */
        if (message.computedUserset)
            ComputedUserset.internalBinaryWrite(message.computedUserset, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.SourcePosition source_position = 3; */
        if (message.sourcePosition)
            SourcePosition.internalBinaryWrite(message.sourcePosition, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.TupleToUserset
 */
export const TupleToUserset = new TupleToUserset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TupleToUserset_Tupleset$Type extends MessageType<TupleToUserset_Tupleset> {
    constructor() {
        super("core.v1.TupleToUserset.Tupleset", [
            { no: 1, name: "relation", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "64", pattern: "^[a-z][a-z0-9_]{1,62}[a-z0-9]$" } } } }
        ]);
    }
    create(value?: PartialMessage<TupleToUserset_Tupleset>): TupleToUserset_Tupleset {
        const message = { relation: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TupleToUserset_Tupleset>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TupleToUserset_Tupleset): TupleToUserset_Tupleset {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string relation */ 1:
                    message.relation = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TupleToUserset_Tupleset, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string relation = 1; */
        if (message.relation !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.relation);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.TupleToUserset.Tupleset
 */
export const TupleToUserset_Tupleset = new TupleToUserset_Tupleset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FunctionedTupleToUserset$Type extends MessageType<FunctionedTupleToUserset> {
    constructor() {
        super("core.v1.FunctionedTupleToUserset", [
            { no: 1, name: "function", kind: "enum", T: () => ["core.v1.FunctionedTupleToUserset.Function", FunctionedTupleToUserset_Function, "FUNCTION_"], options: { "validate.rules": { enum: { definedOnly: true, notIn: [0] } } } },
            { no: 2, name: "tupleset", kind: "message", T: () => FunctionedTupleToUserset_Tupleset, options: { "validate.rules": { message: { required: true } } } },
            { no: 3, name: "computed_userset", kind: "message", T: () => ComputedUserset, options: { "validate.rules": { message: { required: true } } } },
            { no: 4, name: "source_position", kind: "message", T: () => SourcePosition }
        ]);
    }
    create(value?: PartialMessage<FunctionedTupleToUserset>): FunctionedTupleToUserset {
        const message = { function: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FunctionedTupleToUserset>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FunctionedTupleToUserset): FunctionedTupleToUserset {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* core.v1.FunctionedTupleToUserset.Function function */ 1:
                    message.function = reader.int32();
                    break;
                case /* core.v1.FunctionedTupleToUserset.Tupleset tupleset */ 2:
                    message.tupleset = FunctionedTupleToUserset_Tupleset.internalBinaryRead(reader, reader.uint32(), options, message.tupleset);
                    break;
                case /* core.v1.ComputedUserset computed_userset */ 3:
                    message.computedUserset = ComputedUserset.internalBinaryRead(reader, reader.uint32(), options, message.computedUserset);
                    break;
                case /* core.v1.SourcePosition source_position */ 4:
                    message.sourcePosition = SourcePosition.internalBinaryRead(reader, reader.uint32(), options, message.sourcePosition);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FunctionedTupleToUserset, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* core.v1.FunctionedTupleToUserset.Function function = 1; */
        if (message.function !== 0)
            writer.tag(1, WireType.Varint).int32(message.function);
        /* core.v1.FunctionedTupleToUserset.Tupleset tupleset = 2; */
        if (message.tupleset)
            FunctionedTupleToUserset_Tupleset.internalBinaryWrite(message.tupleset, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.ComputedUserset computed_userset = 3; */
        if (message.computedUserset)
            ComputedUserset.internalBinaryWrite(message.computedUserset, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.SourcePosition source_position = 4; */
        if (message.sourcePosition)
            SourcePosition.internalBinaryWrite(message.sourcePosition, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.FunctionedTupleToUserset
 */
export const FunctionedTupleToUserset = new FunctionedTupleToUserset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FunctionedTupleToUserset_Tupleset$Type extends MessageType<FunctionedTupleToUserset_Tupleset> {
    constructor() {
        super("core.v1.FunctionedTupleToUserset.Tupleset", [
            { no: 1, name: "relation", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "64", pattern: "^[a-z][a-z0-9_]{1,62}[a-z0-9]$" } } } }
        ]);
    }
    create(value?: PartialMessage<FunctionedTupleToUserset_Tupleset>): FunctionedTupleToUserset_Tupleset {
        const message = { relation: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FunctionedTupleToUserset_Tupleset>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FunctionedTupleToUserset_Tupleset): FunctionedTupleToUserset_Tupleset {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string relation */ 1:
                    message.relation = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FunctionedTupleToUserset_Tupleset, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string relation = 1; */
        if (message.relation !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.relation);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.FunctionedTupleToUserset.Tupleset
 */
export const FunctionedTupleToUserset_Tupleset = new FunctionedTupleToUserset_Tupleset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ComputedUserset$Type extends MessageType<ComputedUserset> {
    constructor() {
        super("core.v1.ComputedUserset", [
            { no: 1, name: "object", kind: "enum", T: () => ["core.v1.ComputedUserset.Object", ComputedUserset_Object], options: { "validate.rules": { enum: { definedOnly: true } } } },
            { no: 2, name: "relation", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "64", pattern: "^[a-z][a-z0-9_]{1,62}[a-z0-9]$" } } } },
            { no: 3, name: "source_position", kind: "message", T: () => SourcePosition }
        ]);
    }
    create(value?: PartialMessage<ComputedUserset>): ComputedUserset {
        const message = { object: 0, relation: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ComputedUserset>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ComputedUserset): ComputedUserset {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* core.v1.ComputedUserset.Object object */ 1:
                    message.object = reader.int32();
                    break;
                case /* string relation */ 2:
                    message.relation = reader.string();
                    break;
                case /* core.v1.SourcePosition source_position */ 3:
                    message.sourcePosition = SourcePosition.internalBinaryRead(reader, reader.uint32(), options, message.sourcePosition);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ComputedUserset, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* core.v1.ComputedUserset.Object object = 1; */
        if (message.object !== 0)
            writer.tag(1, WireType.Varint).int32(message.object);
        /* string relation = 2; */
        if (message.relation !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.relation);
        /* core.v1.SourcePosition source_position = 3; */
        if (message.sourcePosition)
            SourcePosition.internalBinaryWrite(message.sourcePosition, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.ComputedUserset
 */
export const ComputedUserset = new ComputedUserset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SourcePosition$Type extends MessageType<SourcePosition> {
    constructor() {
        super("core.v1.SourcePosition", [
            { no: 1, name: "zero_indexed_line_number", kind: "scalar", T: 4 /*ScalarType.UINT64*/ },
            { no: 2, name: "zero_indexed_column_position", kind: "scalar", T: 4 /*ScalarType.UINT64*/ }
        ]);
    }
    create(value?: PartialMessage<SourcePosition>): SourcePosition {
        const message = { zeroIndexedLineNumber: "0", zeroIndexedColumnPosition: "0" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SourcePosition>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SourcePosition): SourcePosition {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 zero_indexed_line_number */ 1:
                    message.zeroIndexedLineNumber = reader.uint64().toString();
                    break;
                case /* uint64 zero_indexed_column_position */ 2:
                    message.zeroIndexedColumnPosition = reader.uint64().toString();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SourcePosition, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 zero_indexed_line_number = 1; */
        if (message.zeroIndexedLineNumber !== "0")
            writer.tag(1, WireType.Varint).uint64(message.zeroIndexedLineNumber);
        /* uint64 zero_indexed_column_position = 2; */
        if (message.zeroIndexedColumnPosition !== "0")
            writer.tag(2, WireType.Varint).uint64(message.zeroIndexedColumnPosition);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.SourcePosition
 */
export const SourcePosition = new SourcePosition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CaveatExpression$Type extends MessageType<CaveatExpression> {
    constructor() {
        super("core.v1.CaveatExpression", [
            { no: 1, name: "operation", kind: "message", oneof: "operationOrCaveat", T: () => CaveatOperation },
            { no: 2, name: "caveat", kind: "message", oneof: "operationOrCaveat", T: () => ContextualizedCaveat }
        ]);
    }
    create(value?: PartialMessage<CaveatExpression>): CaveatExpression {
        const message = { operationOrCaveat: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CaveatExpression>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CaveatExpression): CaveatExpression {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* core.v1.CaveatOperation operation */ 1:
                    message.operationOrCaveat = {
                        oneofKind: "operation",
                        operation: CaveatOperation.internalBinaryRead(reader, reader.uint32(), options, (message.operationOrCaveat as any).operation)
                    };
                    break;
                case /* core.v1.ContextualizedCaveat caveat */ 2:
                    message.operationOrCaveat = {
                        oneofKind: "caveat",
                        caveat: ContextualizedCaveat.internalBinaryRead(reader, reader.uint32(), options, (message.operationOrCaveat as any).caveat)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CaveatExpression, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* core.v1.CaveatOperation operation = 1; */
        if (message.operationOrCaveat.oneofKind === "operation")
            CaveatOperation.internalBinaryWrite(message.operationOrCaveat.operation, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* core.v1.ContextualizedCaveat caveat = 2; */
        if (message.operationOrCaveat.oneofKind === "caveat")
            ContextualizedCaveat.internalBinaryWrite(message.operationOrCaveat.caveat, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.CaveatExpression
 */
export const CaveatExpression = new CaveatExpression$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CaveatOperation$Type extends MessageType<CaveatOperation> {
    constructor() {
        super("core.v1.CaveatOperation", [
            { no: 1, name: "op", kind: "enum", T: () => ["core.v1.CaveatOperation.Operation", CaveatOperation_Operation] },
            { no: 2, name: "children", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CaveatExpression }
        ]);
    }
    create(value?: PartialMessage<CaveatOperation>): CaveatOperation {
        const message = { op: 0, children: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CaveatOperation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CaveatOperation): CaveatOperation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* core.v1.CaveatOperation.Operation op */ 1:
                    message.op = reader.int32();
                    break;
                case /* repeated core.v1.CaveatExpression children */ 2:
                    message.children.push(CaveatExpression.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CaveatOperation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* core.v1.CaveatOperation.Operation op = 1; */
        if (message.op !== 0)
            writer.tag(1, WireType.Varint).int32(message.op);
        /* repeated core.v1.CaveatExpression children = 2; */
        for (let i = 0; i < message.children.length; i++)
            CaveatExpression.internalBinaryWrite(message.children[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.CaveatOperation
 */
export const CaveatOperation = new CaveatOperation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RelationshipFilter$Type extends MessageType<RelationshipFilter> {
    constructor() {
        super("core.v1.RelationshipFilter", [
            { no: 1, name: "resource_type", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "128", pattern: "^(([a-z][a-z0-9_]{1,61}[a-z0-9]/)*[a-z][a-z0-9_]{1,62}[a-z0-9])?$" } } } },
            { no: 2, name: "optional_resource_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "1024", pattern: "^([a-zA-Z0-9/_|\\-=+]{1,})?$" } } } },
            { no: 5, name: "optional_resource_id_prefix", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "1024", pattern: "^([a-zA-Z0-9/_|\\-=+]{1,})?$" } } } },
            { no: 3, name: "optional_relation", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "64", pattern: "^([a-z][a-z0-9_]{1,62}[a-z0-9])?$" } } } },
            { no: 4, name: "optional_subject_filter", kind: "message", T: () => SubjectFilter }
        ]);
    }
    create(value?: PartialMessage<RelationshipFilter>): RelationshipFilter {
        const message = { resourceType: "", optionalResourceId: "", optionalResourceIdPrefix: "", optionalRelation: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RelationshipFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RelationshipFilter): RelationshipFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string resource_type */ 1:
                    message.resourceType = reader.string();
                    break;
                case /* string optional_resource_id */ 2:
                    message.optionalResourceId = reader.string();
                    break;
                case /* string optional_resource_id_prefix */ 5:
                    message.optionalResourceIdPrefix = reader.string();
                    break;
                case /* string optional_relation */ 3:
                    message.optionalRelation = reader.string();
                    break;
                case /* core.v1.SubjectFilter optional_subject_filter */ 4:
                    message.optionalSubjectFilter = SubjectFilter.internalBinaryRead(reader, reader.uint32(), options, message.optionalSubjectFilter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RelationshipFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string resource_type = 1; */
        if (message.resourceType !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.resourceType);
        /* string optional_resource_id = 2; */
        if (message.optionalResourceId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.optionalResourceId);
        /* string optional_resource_id_prefix = 5; */
        if (message.optionalResourceIdPrefix !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.optionalResourceIdPrefix);
        /* string optional_relation = 3; */
        if (message.optionalRelation !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.optionalRelation);
        /* core.v1.SubjectFilter optional_subject_filter = 4; */
        if (message.optionalSubjectFilter)
            SubjectFilter.internalBinaryWrite(message.optionalSubjectFilter, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.RelationshipFilter
 */
export const RelationshipFilter = new RelationshipFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubjectFilter$Type extends MessageType<SubjectFilter> {
    constructor() {
        super("core.v1.SubjectFilter", [
            { no: 1, name: "subject_type", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "128", pattern: "^([a-z][a-z0-9_]{1,61}[a-z0-9]/)*[a-z][a-z0-9_]{1,62}[a-z0-9]$" } } } },
            { no: 2, name: "optional_subject_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "1024", pattern: "^(([a-zA-Z0-9/_|\\-=+]{1,})|\\*)?$" } } } },
            { no: 3, name: "optional_relation", kind: "message", T: () => SubjectFilter_RelationFilter }
        ]);
    }
    create(value?: PartialMessage<SubjectFilter>): SubjectFilter {
        const message = { subjectType: "", optionalSubjectId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SubjectFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubjectFilter): SubjectFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string subject_type */ 1:
                    message.subjectType = reader.string();
                    break;
                case /* string optional_subject_id */ 2:
                    message.optionalSubjectId = reader.string();
                    break;
                case /* core.v1.SubjectFilter.RelationFilter optional_relation */ 3:
                    message.optionalRelation = SubjectFilter_RelationFilter.internalBinaryRead(reader, reader.uint32(), options, message.optionalRelation);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SubjectFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string subject_type = 1; */
        if (message.subjectType !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.subjectType);
        /* string optional_subject_id = 2; */
        if (message.optionalSubjectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.optionalSubjectId);
        /* core.v1.SubjectFilter.RelationFilter optional_relation = 3; */
        if (message.optionalRelation)
            SubjectFilter_RelationFilter.internalBinaryWrite(message.optionalRelation, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.SubjectFilter
 */
export const SubjectFilter = new SubjectFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubjectFilter_RelationFilter$Type extends MessageType<SubjectFilter_RelationFilter> {
    constructor() {
        super("core.v1.SubjectFilter.RelationFilter", [
            { no: 1, name: "relation", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { maxBytes: "64", pattern: "^([a-z][a-z0-9_]{1,62}[a-z0-9])?$" } } } }
        ]);
    }
    create(value?: PartialMessage<SubjectFilter_RelationFilter>): SubjectFilter_RelationFilter {
        const message = { relation: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SubjectFilter_RelationFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubjectFilter_RelationFilter): SubjectFilter_RelationFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string relation */ 1:
                    message.relation = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SubjectFilter_RelationFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string relation = 1; */
        if (message.relation !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.relation);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message core.v1.SubjectFilter.RelationFilter
 */
export const SubjectFilter_RelationFilter = new SubjectFilter_RelationFilter$Type();
